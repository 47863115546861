<template>
  <div
    class="past-score overflow-auto position-relative"
    :class="{'overflow-hidden': pageLoading}"
  >
    <base-header
      :title="$t('我的成績單')"
      :is-title-bold="true"
      :left-arrow="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>
    <loading-wrapper v-if="pageLoading"></loading-wrapper>
    <div
      v-if="pastScores.length === 0"
      class="empty d-flex flex-column align-items-center justify-content-center"
    >
      <i class="icon-Clipboard-list"></i>
      <p class="text-20 font-weight-bold my-2">{{ $t('目前沒有成績單') }}</p>
      <p class="m-0">{{ $t('檢定後歷史成績單將會顯示在這') }}</p>
    </div>
    <div v-else class="scores-container scroll-bar">
      <div class="transcript-container">
        <transcript-card
          v-for="score in pastScores"
          :key="score.id"
          :score="score"
        ></transcript-card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import TranscriptCard from '@/components/Verification/TranscriptCard.vue';

export default {
  components: {
    BaseHeader,
    LoadingWrapper,
    TranscriptCard,
  },
  data() {
    return {
      pageLoading: true,
      breadcrumb: [
        {
          name: this.$t('檢定'),
          enable: true,
          router: 'verification',
        },
        {
          name: this.$t('我的成績單'),
          enable: false,
          router: 'past-scores',
        },
      ],
    };
  },
  computed: {
    pastScores() {
      return this.$store.getters['verification/pastScores'];
    },
    totalScores() {
      return this.$store.getters['verification/totalScores'];
    },
  },
  watch: {
    totalScores: {
      handler() {
        if (this.totalScores != null) {
          this.pageLoading = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getTotalScores();
  },
  methods: {
    getTotalScores() {
      return this.$store.dispatch('verification/getTotalScores');
    },
  },
};
</script>

<style lang="scss" scoped>
.past-score {
  .empty {
    height: calc(100% - 56px);
    color: $font-grayscale-3;
    i {
      color: rgba($font-grayscale-3, 0.5);
      font-size: 150px;
    }
  }
  .scores-container {
    overflow: overlay;
    height: calc(100% - 56px);
    padding: 16px;
  }
}

@media screen and (min-width: 768px) {
  .past-score {
    .empty {
      height: calc(100% - 80px);
    }
    .scores-container {
      height: calc(100% - 80px);
      padding: 0;
    }
    .transcript-container {
      max-width: 720px;
      margin: 0 auto;
      padding: 24px 16px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .past-score {
    .transcript-container {
      max-width: 1140px;
    }
  }
}
</style>
