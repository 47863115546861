<template>
  <div
    class="transcript-card bg-white d-flex align-items-center justify-content-between cursor-pointer"
    @click="$router.push({name: 'score', params: {scoreId: score.id}})"
  >
    <div class="left d-flex align-items-center">
      <span
        class="is-passed text-32 mr-3"
        :class="score.isPass ? 'icon-Colorful-correct' : 'icon-Colorful-error'"
      >
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
      <div class="content text-truncate">
        <p class="mb-1 text-truncate" style="line-height: 28px">
          <span>{{ score.isPass ? $t('已通過') : $t('未通過') }}</span> -
          {{ level }}{{ $t('棋力檢定') }}
        </p>
        <p class="pass-time m-0 text-12" style="line-height: 20px">
          {{ date }}
        </p>
      </div>
    </div>

    <i
      v-if="$device.isMobile"
      class="action-btn text-primary icon-Chevron-right"
    ></i>
    <b-button v-else variant="primary" size="md">
      {{ $t('查看') }}
    </b-button>
  </div>
</template>

<script>
import day from 'dayjs';

import rankList from '@/json/rank.json';

export default {
  props: {
    score: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      rankList,
    };
  },
  computed: {
    level() {
      if (this.score.rank != null) {
        return this.rankList.find((rank) => {
          return rank.value === this.score.rank;
        }).label;
      } else {
        return '30k';
      }
    },
    date() {
      if (this.score.updatedAt != null) {
        return day(this.score.updatedAt).format('YYYY-MM-DD');
      } else {
        return day().format('YYYY-MM-DD');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.transcript-card {
  border-radius: 10px;
  padding: 14px 16px;
  box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .left {
    width: 80%;
  }
  .is-passed {
    font-size: 32px;
  }
  .pass-time {
    color: $font-grayscale-2;
  }
  .action-btn {
    padding: 7px;
    font-size: 36px;
  }
  .icon-Colorful-correct,
  .icon-Colorful-error,
  .icon-Colorful-correct-gray {
    position: relative;
  }
  .icon-Colorful-correct-gray .path2,
  .icon-Colorful-correct .path2,
  .icon-Colorful-error .path2 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .icon-Colorful-correct-gray .path2::before,
  .icon-Colorful-correct .path2::before,
  .icon-Colorful-error .path2::before {
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .transcript-card {
    padding: 24px 24px 24px 20px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
