<template>
  <div
    class="loading bg-bgcontainer w-100 h-100 position-absolute d-flex flex-column align-items-center justify-content-center"
    :class="$isJungo ? 'jungo-loading' : 'text-secondary'"
  >
    <b-spinner class="spinner"></b-spinner>
    <div class="loading-text mt-3">{{ loadingText }}</div>
  </div>
</template>

<script>
export default {
  name: 'LoadingWrapper',
  props: {
    loadingText: {
      type: String,
      default() {
        return this.$t('載入中');
      },
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.loading {
  z-index: 999;
  top: 0;
  right: 0;
  .spinner {
    width: 48px;
    height: 48px;
  }
  .loading-text {
    color: $font-grayscale-2;
  }
  &.jungo-loading {
    color: $font-secondary;
  }
}
</style>
